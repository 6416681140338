import { createContext, useContext } from "solid-js";
import type { Accessor, Setter } from "solid-js";
import type { SetStoreFunction, Store } from "solid-js/store";
import type {
  StorageObject,
  StorageSetter,
  RomaUserSessionData,
} from "./types";
import type { TrackHandler } from "@solid-primitives/analytics";
import { AccessorWithLatest } from "@solidjs/router";
import { RomaSessionData } from "~/services/session/session";
import { PERMISSION, type Permission } from "~/services/roma-api/account/types";
import { FavouriteTypeOption } from "~/services/roma-api/favourites/types";
import { OrderStore } from "~/routes/(layout)/order/(order)";
import type { ErrorHandlingResult, PanelControlProps } from "~/components/SiteContextProviderAndSessionManager";
import {
  CartDetail,
  SubmittedCartDetail,
} from "~/services/roma-api/cart/types";
import { ListCartResponse } from "~/services/roma-api/cart";
import {
  CheckoutStore,
  FormattedCart,
  FormattedCartLines,
  MinAmountMet,
} from "~/routes/(layout)/checkout/[cart_id]";
import { LineItem } from "~/services/roma-api/cart/types-lineItems";
import { PT } from "./products";
import type { PermissionChecker } from "~/services/permissions";
import type { RecentlySearched } from "./createRecentlySearched";
import { RecentlyViewed, RecentlyViewedProduct } from "./createRecentlyViewed";

// ** SITE CONTEXT ** //

type SiteCtx = {
  headerVisible: [Accessor<boolean>, Accessor<boolean>, Setter<boolean>];
  isLoading: () => boolean;
  breakpoints: Breakpoints;
  global?: Store<any>;
  track: TrackHandler;
  //? adding trackFBQ ? ask dave..
  trackFBQ: (...args: any) => void;
  VERSION?: string;
  isDarkMode?: Accessor<boolean>;
  setIsDarkMode?: Setter<boolean>;
  // session?: StorageObject<string>;
  // setSession?: StorageSetter<string, RomaSessionData>;
  // clearSession?: () => void;
  // addToRecentlyViewed?: (sku: string, type: string) => undefined;
  // isPartner?: Accessor<boolean>;
  // measurementUnit?: Accessor<string>;
  // setMeasurementUnit?: Setter<string>;
  recentlySearched: RecentlySearched;
  recentlyViewed: RecentlyViewed;
  // ? local needed? ..likely can be removed
  local: {
    recentlySearched: string[];
    recentlyViewed: RecentlyViewedProduct[];
  };
  panel: Accessor<PanelControlProps>;
  setPanel: Setter<PanelControlProps>;
};

export const SiteContext = createContext<SiteCtx>();

export const useSiteContext = () => {
  const ctx = useContext(SiteContext);
  if (!ctx) {
    throw new Error(
      "useSiteContext: cannot find SiteContext. Make sure component is wrapped in SiteContextProvider"
    );
  }
  return ctx;
};

// ** SESSION CONTEXT ** //

type SessionCtx = {
  session: AccessorWithLatest<RomaSessionData | null | undefined>;
  setSession: (data: Partial<RomaSessionData>) => Promise<void>;
  clearSession: () => Promise<void>;
  isPartner: Accessor<boolean | undefined>;
  toggleFav: (sku: string, type: FavouriteTypeOption, id?: string) => Promise<void>;
  isFavourited: (favMap: Map<any, any> | undefined, sku: string, type: FavouriteTypeOption) => boolean;
  aCartActionIsPending: Accessor<boolean>;
  permission: PermissionChecker;
};

export const SessionContext = createContext<SessionCtx>();

export const useSessionContext = () => {
  const ctx = useContext(SessionContext);
  if (!ctx) {
    throw new Error(
      "useSessionContext: cannot find SessionContext. Make sure component is wrapped in SessionContextProvider"
    );
  }
  return ctx;
};

// ** ITEM ORDER CONTEXT ** //

type ItemOrderCtx = {
  orderData: Store<OrderStore>;
  setOrderData: SetStoreFunction<OrderStore>;
  searchValue: Accessor<string | undefined>;
  // params?: any // needed / used at all in prev codebase?
  // setParams?: any // needed / used at all in prev codebase?
};

export const ItemOrderContext = createContext<ItemOrderCtx>();

export const useItemOrderContext = () => {
  const ctx = useContext(ItemOrderContext);
  if (!ctx) {
    throw new Error(
      "useItemOrderContext: cannot find ItemOrderContext. Make sure component is wrapped in ItemOrderContextProvider"
    );
  }
  return ctx;
};

// ** CHECKOUT CONTEXT ** //

type CheckoutCtx = {
  cart: AccessorWithLatest<FormattedCart | null | undefined>;
  // carts: AccessorWithLatest<ListCartResponse | undefined>;
  lines: Accessor<FormattedCartLines | undefined>;
  minAmountMet: Accessor<MinAmountMet>;
  checkoutData: Store<CheckoutStore>;
  setCheckoutData: SetStoreFunction<CheckoutStore>;
  updateQuantity: (id: string, qty: number, type: PT) => Promise<void>;
  removeLine: (id: string) => Promise<void>;
  clearCart: () => Promise<void>;
  handlePlaceOrder: () => Promise<void>;
  BPSContainsNonCorner: Accessor<boolean>;
  isBPS: Accessor<boolean>;
  cannotPickupGFPF: Accessor<boolean>;
  isReviewValid: Accessor<boolean>;
  disableCheckoutButtons: Accessor<boolean>;
};

export const CheckoutContext = createContext<CheckoutCtx>();

export const useCheckoutContext = () => {
  const ctx = useContext(CheckoutContext);
  if (!ctx) {
    throw new Error(
      "useCheckoutContext: cannot find CheckoutContext. Make sure component is wrapped in CheckoutContextProvider"
    );
  }
  return ctx;
};

// ** ERROR CONTEXT ** //

export type ErrorAction = {
  label: string;
  action: () => void | Promise<void>;
  variant?: "primary" | "secondary";
  dismissAfter?: boolean;
};

export type ErrorNotification = {
  id: string;
  title: string;
  message: string;
  timestamp: number;
  severity: "critical" | "warning" | "info";
  actions?: ErrorAction[];
  suggestions?: string[];
  suggestionsLabel?: string;
  originalError?: Error;
  // Sentry integration
  sentryEventId?: string;
  allowFeedback?: boolean;
  feedbackSubmitted?: boolean;
  suppressSentryReport?: boolean;
  // Controls
  dismissible?: boolean;
  autoDisappear?: boolean;
  autoDisappearDelay?: number;
  showDetails?: boolean;
  // Tracking
  occurrences: number;
};

export type ErrorCtx = {
  notifications: ErrorNotification[];
  addError: (error: Error, config?: Partial<ErrorNotification>) => ErrorHandlingResult;
  removeError: (id: string) => void;
  submitFeedback: (id: string, feedback: string) => Promise<void>;
  clearErrors: () => void;
  reportErrorToSentry: (err: Error) =>string;
  updateNotificationSentryId: (notificationId: string, sentryEventId: string) => void
};

export const ErrorContext = createContext<ErrorCtx>();

export const useErrorContext = () => {
  const ctx = useContext(ErrorContext);
  if (!ctx) {
    throw new Error(
      "useErrorContext: cannot find ErrorContext. Make sure component is wrapped in ErrorContextProvider"
    );
  }
  return ctx;
};

type Breakpoints = {
  sm: boolean;
  md: boolean;
  lg: boolean;
  xl: boolean;
  xxl: boolean;
};
